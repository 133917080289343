/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
  
  a {
    color: #47b2e4;
    text-decoration: none !important;
  }
  
  a:hover {
    color: #73c5eb;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Jost", sans-serif;
  }
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    background: rgb(46,49,145); 
    transition: all 0.5s;
    z-index: 997;
    padding: 10px 0;
    box-shadow: 5px 5px 8px rgba(7, 9, 12, 0.25);
  }
  
  #header.header-scrolled, #header.header-inner-pages {
    background: rgba(40, 58, 90, 0.9);
  }
  
  /* #header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
  } */
  
  #header .logo a {
    color: #fff;
  }
  
  #header .logo img {
    max-height: 90px;
    width: 200px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  .nav-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .nav-menu > ul {
    display: flex;
  }
  
  .nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    padding: 10px 0 10px 28px;
  }
  
  .nav-menu a {
    display: block;
    position: relative;
    color: #fff;
    transition: 0.3s;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
  }
  
  .nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
    color: #47b2e4;
  }
  
  .nav-menu .drop-down ul {
    border-radius: 8px;
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
  }
  
  .nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #0c3c53;
  }
  
  .nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
    color: #47b2e4;
  }
  
  .nav-menu .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
  }
  
  .nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
  }
  
  .nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
  }
  
  .nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
  }
  
  .nav-menu .drop-down .drop-down > a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
  }
  
  @media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
      left: -90%;
    }
    .nav-menu .drop-down .drop-down:hover > ul {
      left: -100%;
    }
    .nav-menu .drop-down .drop-down > a:after {
      content: "\ea9d";
    }
  }
  /*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 80vh;
    background: rgb(46,49,145,0.9 ); 
  }
  
  #hero .container {
    padding-top: 72px;
  }
  
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
  }
  
  #hero h2 {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 50px;
    font-size: 24px;
  }
  
  #hero .btn-get-started {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px 0 0 0;
    color: #fff;
    background: #47b2e4;
  }
  
  #hero .btn-get-started:hover {
    background: #209dd8;
  }
  
  #hero .btn-watch-video {
    font-size: 16px;
    display: inline-block;
    padding: 10px 0 8px 40px;
    transition: 0.5s;
    margin: 10px 0 0 25px;
    color: #fff;
    position: relative;
  }
  
  #hero .btn-watch-video i {
    color: #fff;
    font-size: 32px;
    position: absolute;
    left: 0;
    top: 7px;
    transition: 0.3s;
  }
  
  #hero .btn-watch-video:hover i {
    color: #47b2e4;
  }
  
  #hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  
  @media (max-width: 991px) {
    #hero {
      height: 100vh;
      text-align: center;
    }
    #hero .animated {
      -webkit-animation: none;
      animation: none;
    }
    #hero .hero-img {
      text-align: center;
    }
    #hero .hero-img img {
      width: 50%;
    }
  }
  
  @media (max-width: 768px) {
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    #hero .hero-img img {
      width: 70%;
    }
  }
  
  @media (max-width: 575px) {
    #hero .hero-img img {
      width: 80%;
    }
    #hero .btn-get-started {
      font-size: 16px;
      padding: 10px 24px 11px 24px;
    }
    #hero .btn-watch-video {
      font-size: 16px;
      padding: 10px 0 8px 40px;
      margin-left: 20px;
    }
    #hero .btn-watch-video i {
      font-size: 32px;
      top: 7px;
    }
  }
  
  @-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  /* .wave-container {
    position: relative;
    text-align: center;
    overflow: hidden;
  } */
 /*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f3f5fa;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #37517e;
  }
  
  .section-title h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #47b2e4;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # clients
  --------------------------------------------------------------*/
  .clients {
    padding: 12px 0;
    text-align: center;
  }
  
  .clients img {
    max-width: 100%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 1px 0;
    -webkit-filter: grayscale(100);
    filter: grayscale(100);
  }
  
  .clients img:hover {
    -webkit-filter: none;
    filter: none;
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .clients img {
      max-width: 40%;
    }
  }
  /*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
 
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.3s;
    line-height: 1;
    color: #47b2e4;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #47b2e4;
  }
  
  .about .content .btn-learn-more:hover {
    background: #47b2e4;
    color: #fff;
    text-decoration: none;
  }
  
  .about .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  .about .about-img{
      width: 60%;
  }

 
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 50px 30px;
    transition: all ease-in-out 0.4s;
    background: #fff;
  }
  
  .services .icon-box .icon {
    margin-bottom: 10px;
  }
  
  .services .icon-box .icon i {
    color: #47b2e4;
    font-size: 36px;
    transition: 0.3s;
  }
  
  .services .icon-box h4 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  .services .icon-box h4 a {
    color: #37517e;
    transition: ease-in-out 0.3s;
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .icon-box:hover {
    transform: translateY(-10px);
  }
  
  .services .icon-box:hover h4 a {
    color: #47b2e4;
  } 
   /*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), fixed center center;
    background-size: cover;
    padding: 120px 0;
  }
  
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  
  .cta .cta-btn {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .cta .cta-btn:hover {
    background: #47b2e4;
    border: 2px solid #47b2e4;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  /*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
    padding-left: 50px;
  }
  
  .testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 30px 60px;
    margin: 30px 15px;
    min-height: 200px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 10px;
    border: 6px solid #fff;
    position: absolute;
    left: -45px;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: #e1f0fa;
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
  }
  
  .testimonials .owl-nav, .testimonials .owl-dots {
    margin-top: 5px;
    text-align: center;
  }
  
  .testimonials .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
  }
  
  .testimonials .owl-dot.active {
    background-color: #3498db !important;
  }
  
  @media (max-width: 767px) {
    .testimonials .testimonial-wrap {
      padding-left: 0;
    }
    .testimonials .testimonial-item {
      padding: 30px;
      margin: 15px;
    }
    .testimonials .testimonial-item .testimonial-img {
      position: static;
      left: auto;
    }
  }

